import React from "react";
import styled from "styled-components";
import mq from '../../styles/media-query';

const Flex = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const FlexItem = styled.div`
  height: 30px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  ${mq.sm`
    height: 32px;
  `}
  ${mq.md`
    height: 50px;
  `}
  img {
    height: 100%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.1;
  font-weight: 700;
  ${mq.sm`
    font-size: 15px;
  `}
  ${mq.md`
    font-size: 20px;
  `}
`;

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: ${props => props.gapBelow ? 20 : 0}px;
`;

export default function AsSeenOn({gapBelow=true}) {
    return (
        <Wrapper gapBelow={gapBelow}>
            <Flex>
                <FlexItem><Text>As seen<br/>on</Text></FlexItem>
                <FlexItem><img src="/cnn.svg" alt="CNN Logo"/></FlexItem>
                <FlexItem><img src="/foxnews.svg" alt="FOX News Logo"/></FlexItem>
                <FlexItem><img src="/cnbc.svg" alt="CNBC Logo"/></FlexItem>
                <FlexItem><img src="/KTLA5.png" alt="KTLA5 Logo"/></FlexItem>
            </Flex>
        </Wrapper>
    )
}
