import React from "react";
import styled from "styled-components";
import { FaCheck as CheckmarkIcon } from 'react-icons/fa';
import { FaMinus as CloseIcon } from 'react-icons/fa';
import mq from '../../styles/media-query';

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: var(--brandSecondary);
  align-items: center;
  margin-bottom: ${props => props.header ? '5px' : 0};
  
  ${mq.xs`
     grid-template-columns: 1fr 2fr 2fr;
  `}
  
  &:nth-child(even) {
    background-color: #ebebeb;
    > div:nth-child(2) {
      background-color: #e6f3f8;
    }
  }
`;

const Headline = styled.div`
  text-transform:uppercase;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  min-height: 35px;
  padding-left: 0;
  ${mq.sm`
    font-size: 20px;
    padding-left: ${props => props.noIndent ? 0 : '50px'};
  `}

  img {
    height: 30px;
    margin-left: 4px;
    display: none;
    
    ${mq.xs`
        height: 35px;
        margin-left: 8px;
        display: unset;
    `}
  }
`;

const Issue = styled.div`
  text-transform:uppercase;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  color: var(--textOverLight);
  padding: 5px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  
  ${mq.sm`
     font-size: 14px;
  `}
`;

const DB = styled.div`
  text-transform:uppercase;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 4px;
  height: 100%;
  display: flex;
  align-items: center;
  
  ${mq.xs`
    font-size: 14px;
    padding: 5px 8px;
  `}
  
  ${mq.sm`
    font-size: 17px;
    padding: 5px 10px;
  `}
  
  ${mq.md`
    font-size: 19px;
  `}
  
  ${mq.lg`
    font-size: 22px;
  `}
  
  ${mq.xl`
    font-size: 23px;
  `}
  
  > div {
    display: flex;
    align-items: center;
  }
`;

const Other = styled(DB)`
  font-size: 12px;
  color: var(--textOverLight);
  text-transform: none;
  
  ${mq.sm`
     font-size: 14px;
  `}
  
  ${mq.md`
     font-size: 15px;
  `}
  
  ${mq.lg`
     font-size: 17px;
  `}
  
  ${mq.xl`
     font-size: 18px;
  `}
`;

const Checkmark = styled(CheckmarkIcon)`
  margin-right: 5px;
  color: var(--brandPrimary);
  font-size: 22px;
  width: 15px;

  ${mq.xs`
     font-size: 26px;
     width: 35px;
  `}
`;

const XIcon = styled(CloseIcon)`
  margin-right: 5px;
  color: #9b2222;
  font-size: 15px;
  width: 20px;
 
  ${mq.xs`
     font-size: 26px;
     width: 35px;
  `}
`;

const VsTable = () => {
    return (
        <>
            <Row header>
                <div><Headline noIndent>Issue</Headline></div>
                <div><Headline>Direct Buyers <img src="/db_logo_house.png" alt="Direct Buyers Logo"/></Headline></div>
                <div><Headline>Selling With a Realtor</Headline></div>
            </Row>
            <Row>
                <Issue><div>Commissions</div></Issue>
                <DB><div><Checkmark/></div><span>None!</span></DB>
                <Other><div><XIcon/></div><span>3-6% paid by the SELLER</span></Other>
            </Row>
            <Row>
                <Issue><div>Escrow Costs</div></Issue>
                <DB><div><Checkmark/></div><span>None!</span></DB>
                <Other><div><XIcon/></div><span>1-2% paid by the SELLER</span></Other>
            </Row>
            <Row>
                <Issue><div>Home Prep</div></Issue>
                <DB><div><div><Checkmark/></div><span>None! Sell As-Is</span></div></DB>
                <Other><div><div><XIcon/></div><span>Seller repairs &amp; prepares home for showings</span></div></Other>
            </Row>
            <Row>
                <Issue><div>Appraisals &amp; Inspections</div></Issue>
                <DB><div><div><Checkmark/></div><span>None!</span></div></DB>
                <Other><div><div><XIcon/></div><span>Yes, buyer's contingencies</span></div></Other>
            </Row>
            <Row>
                <Issue><div>Days to sell home</div></Issue>
                <DB><div><div><Checkmark/></div><span>10 Days!</span></div></DB>
                <Other><div><div><XIcon/></div><span>45-90+ DAYS</span></div></Other>
            </Row>
            <Row>
                <Issue><div>Offer</div></Issue>
                <DB><div><div><Checkmark/></div><span>Cash! No Contingencies</span></div></DB>
                <Other><div><div><XIcon/></div><span>Loan &amp; Appraisal Contingencies</span></div></Other>
            </Row>
            <Row>
                <Issue><div>Closing</div></Issue>
                <DB><div><div><Checkmark/></div><span>We close or you keep deposit</span></div></DB>
                <Other><div><div><XIcon/></div><span>Buyers or their lenders can delay/cancel a sale</span></div></Other>
            </Row>
        </>
    )
}

export default VsTable;