import React from "react";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled from "styled-components";
import mq from '../../styles/media-query';

const StyledPlayer = styled.div`
  .rhap_container {
    box-shadow: none !important;
  }
  .rhap_additional-controls {
    display: none;
  }
  
  * {
    color: var(--brandSecondary);
  }
  .rhap_progress-indicator {
    background-color: var(--brandSecondary);
  }
`;

export const AudioInfoLink = styled.a`
  color: var(--brandSecondary);
  font-size: 14px;
`;

export const AudioTitle = styled.div`
  color: var(--brandSecondary);
  font-size: 22px;
  border-bottom: 1px solid var(--brandPrimary);
  margin-bottom: 10px;
  ${mq.sm`
    font-size: 24px;
  `}
`;

export const AudioCopy = styled.div`
  color: var(--textOverLight);
  font-size: 16px;
  line-height: 1.2;
`;

export default function AudioPlayerComponent(props) {
    const {children, fileUrl} = props;
    return (
        <StyledPlayer>
            {children}
            <AudioPlayer
                preload="metadata"
                customAdditionalControls={[]}
                customVolumeControls={[]}
                layout="horizontal-reverse"
                autoplay={false}
                src={fileUrl}
            />
        </StyledPlayer>
    )
}
